import { React } from "react";
import "./Modal.css";
import scopeImg from "../images/icons/WEBANIM_UFO_3.gif"

function ModalScope({ closeModal }) {
  return (
    <div className="modalBackgroundScope">
      <div className="modalContainerScope">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="scope">
            <img src={scopeImg} alt="ufo in scope" />
        </div>
      </div>
    </div>
  );
}

export default ModalScope;
