import React from "react";
import "./Modal.css";
import futurdevgif from "../images/FuturDevs_gif.gif";

function ModalMetaverse({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Future Developements</h1>
        </div>
        <div className="body">
          <p>
            Here is a glimpse of Original Painting's future developments for the
            community:
          </p>
          <p>
            Community token, Exclusive collectibles, 3D-NFT of your painting, Demiurge
            Academy!
          </p>
          <div className="futurdevimg">
            <img src={futurdevgif} alt="Future developments" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalMetaverse;
