import { React } from "react";
import "./Modal.css";

function ModalVautour() {
  return (
    <div className="vautouranim">
    </div>
  );
}

export default ModalVautour;
