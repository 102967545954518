import React from "react";
import "./Modal.css";
import cured from "../images/cured2.png";

function Modal({ closeModal }) {
  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>

        <div className="title">
          <img className="curedimg" src={cured} alt="C.U.R.E.D levels" />
          <h3>The internal rarity system</h3>
        </div>
        <div className="body">
          <p>
            Each of the 200 elements in the collection has an internal score
            depending on its rarity
          </p>
        </div>
        <div className="curedexplain">
          <p>Common</p>
          <p>Unco</p>
          <p>Rare</p>
          <p>Epic</p>
          <p>Demiurge</p>
        </div>
        <div className="footer"></div>
        <div className="discover">
          <button>
            <a
              href="https://google.com"
              target="_blank"
              rel="noreferrer"
              class="button"
            >
              Discover
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;
