import React from "react";
import "./Modal.css";
import earthImg from "../images/webearth.png";

function ModalTableau({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Democratize Art</h1>
        </div>
        <div className="body">
          <p>
            More than a way of expression, visual art is a driving force for
            children education and emancipation.
          </p>
          <p>
            By supporting Original Painting, you will contribute promoting art
            in multiple ways.
          </p>
          <p>
            As a first step in the project, we plan to perform live oil painting
            sessions as well as initiations to digital art.
          </p>
          <p>
            Then we will bring you with us and diversify our actions. We have
            big plans; this is just the beginning!
          </p>
        </div>
        <div className="earth">
          <img src={earthImg} alt='earth' />
        </div>
      </div>
    </div>
  );
}

export default ModalTableau;
