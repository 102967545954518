import { React } from "react";
import "./Modal.css";
import Gift from "../images/icons/WEBANIM_POPMYSTERE-ALL.png";


function ModalPeluches({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>

        <div className="title">
          <h1>Original Gifts</h1>
        </div>
        <div className="body">
          <p>
            By entering Original Painting community, you will have a chance to
            claim unique non-digital collectibles.
          </p>
          <span className="full-text">
            <p>
              Those collectibles will be your bridge between the metaverse and the
              actual world you live in. Let the people know who we are! Let your
              family know what the power of decentralized digital art is.
            </p>
            <p>
              3 types of collectibles can be extracted from the metaverse straight to
              your living room depending on elements you will find on your
              painting.
            </p>
          </span>
          <span className="short-text">
            <p>
              3 types of collectibles can be extracted from the metaverse straight to
              your living room depending on elements you will find on your
              painting.
            </p>
          </span>
          {/* <div className="discover">
            <button className="navButton">
              <a
                href="https://original-painting.gitbook.io/original-painting/"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                Discover
              </a>
            </button>
          </div> */}
        </div>

          <div className="mystery">
            {/* <img src={Gift1} alt="gift 1"/> */}
            <img src={Gift} alt="gifts" />
            {/* <img src={Gift3} alt="gift 3" /> */}
          </div>
      </div>
    </div>
  );
}

export default ModalPeluches;
