import React from "react";
import "./Modal.css";
// import videoclip from "../images/animation_originalpainting_2_10.mp4";
import videoincoming from "../images/comingsoon.png";

function ModalTv({ closeModal }) {
  return (
    <div className="modalBackgroundTv">
      <div className="modalContainerTv">
        <div className="projectVideo">
          {/* <video 
          controls
          controlslist="nodownload" >
            <source src={videoclip} type="video/mp4" />
          </video> */}
<img src={videoincoming} className="march19" alt = "coming soon" />

          <div className="titleCloseBtn">
            <button onClick={() => closeModal(false)}> X </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalTv;
