import { React } from "react";
import "./Modal.css";

function ModalInfo({ closeModal }) {
  return (
    <div className="modalBackgroundInfo">
      <div className="modalContainerInfo">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>

        <div className="title">
          <h1>Tips</h1>
        </div>
        <div className="body">
          <div className="web-info">
            <p> hover: animation </p>
          </div>
          <div className="mobile-info">
            <p> press: animation </p>
          </div>
          <p> click: information </p>
        </div>
      </div>
    </div>
  );
}

export default ModalInfo;
