import React from "react";
import "./Modal.css";
import voteProcessImg from "../images/VOTE_PROCESS_WEBSITE.png";

function ModalVote({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>

        <div className="title">
          <h1>Vote!</h1>
        </div>
        <div className="body">
          <p>
            Vote for your favorite paintings and ascertain the Most Valuable Paintings
            of the collection!
          </p>
        </div>
        <div className="voteprocess">
          <img src={voteProcessImg} alt='vote process' />
        </div>
      </div>
    </div>
  );
}

export default ModalVote;
