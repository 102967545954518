import { React, useState } from "react";
import AOS from "aos";
import Link from "react-scroll/modules/components/Link";
import styled from "styled-components";

import './aos.css';
import "./Main.css";
import "./Modal.css";

import backgroundimg from "../images/fond4.png";
import treeimg from "../images/tree11.png";
import pays from "../images/pays8.png";
import logo from "../images/logo512.png";
import roadmapImg from "../images/ROADMAP_WEBSITEv7.png";

import Modal from "./Modal";
import ModalTv from "./ModalTv";
import ModalPencil from "./ModalPencil";
import ModalMetaverse from "./ModalMetaverse";
import ModalNft from "./ModalNft";
import ModalFlacons from "./ModalFlacons";
import ModalBook from "./ModalBook";
import ModalEscargotTw from "./ModalEscargotTw";
import ModalEscargotDc from "./ModalEscargotDc";
import ModalPeluches from "./ModalPeluches";
import ModalTableau from "./ModalTableau";
import ModalVote from "./ModalVote";
import ModalInfo from "./ModalInfo";
import ModalVautour from "./ModalVautour";
import ModalLampe from "./ModalLampe";
import ModalScope from "./ModalScope";

AOS.init();

const MainPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalTv, setOpenModalTv] = useState(false);
  const [openModalPencil, setOpenModalPencil] = useState(false);
  const [openModalMetaverse, setOpenModalMetaverse] = useState(false);
  const [openModalNft, setOpenModalNft] = useState(false);
  const [openModalFlacons, setOpenModalFlacons] = useState(false);
  const [openModalBook, setOpenModalBook] = useState(false);
  const [openModalEscargotTw, setOpenModalEscargotTw] = useState(false);
  const [hideEscargotTw, setHideEscargotTw] = useState(true);
  const [openModalEscargotDc, setOpenModalEscargotDc] = useState(false);
  const [hideEscargotDc, setHideEscargotDc] = useState(true);
  const [hideVautour, setHideVautour] = useState(true);
  const [openModalPeluches, setOpenModalPeluches] = useState(false);
  const [openModalTableau, setOpenModalTableau] = useState(false);
  const [openModalVote, setOpenModalVote] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);
  const [openModalVautour, setOpenModalVautour] = useState(false);
  const [openModalLampe, setOpenModalLampe] = useState(false);
  const [openModalScope, setOpenModalScope] = useState(false);

  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <CustomDiv>
      <div>
        <div className="divbackground">
          <img src={backgroundimg} className="background" alt="background"/>
        </div>
        <div className="divpays">
          <img src={pays} className="pays" alt="mountains"/>
        </div>
        <div className="divtree">
          <img src={treeimg} className="tree" alt="tree"/>
        </div>
      </div>
      <div className="navbar">
        <li>
          <NavButton
            to="roadmap1"
            smooth={true}
            duration={1000}
            spy={true}
            exact="true"
            offset={80}
          >
            ROADMAP
          </NavButton>
        </li>
        <li>
          <NavButton
            to="linkspart"
            smooth={true}
            duration={1000}
            spy={true}
            exact="true"
            offset={80}
          >
            MINT
          </NavButton>
        </li>
        <NavLogo 
                    to="heropart"
                    smooth={true}
                    duration={1000}
                    spy={true}
                    exact="true"
                    // offset={120}
                    >
           <img src={logo} alt="original painting logo" className="logo" />
        </NavLogo>
       
        <li>
          <NavButton
            to="videopart"
            smooth={true}
            duration={1000}
            spy={true}
            exact="true"
            offset={80}
          >
            VIDEO
          </NavButton>
        </li>
        <li>
          <NavButton
            to="curedpart"
            smooth={true}
            duration={1000}
            spy={true}
            exact="true"
            offset={100}
          >
            DISCOVER
          </NavButton>
        </li>
      </div>
      <ContainerHero id="heropart">
      </ContainerHero>
      <ContainerVideo id="videopart">
        <button
          className="openTvBtn"
          onClick={() => {
            setOpenModalTv(true);
          }}
        ></button>
        {openModalTv && <ModalTv closeModal={setOpenModalTv} />}
       
       
        <button
          className="openPeluchesBtn"
          onClick={() => {
            setOpenModalPeluches(true);
          }}
        ></button>
        {openModalPeluches && (
          <ModalPeluches closeModal={setOpenModalPeluches} />
        )}
        
        <button
          className="openLampeBtn"
          onClick={() => {
            setOpenModalLampe(true);
          }}
        ></button>
        {openModalLampe && (
          <ModalLampe closeModal={setOpenModalLampe} />
        )}

<button
          className="openScopeBtn"
          onClick={() => {
            setOpenModalScope(true);
          }}
        ></button>
        {openModalScope && (
          <ModalScope closeModal={setOpenModalScope} />
        )}

      </ContainerVideo>

      <ContainerRoadmap id="roadmap1">
        <div className="roadmapcontainer aos-item"   
        data-aos="fade-up"
        data-aos-duration="1000"
        // data-aos-anchor-placement="center-top"
        ><img src={roadmapImg} alt="roadmap"/></div>

        <div className="feuillegauchecontainer">
        <div className="feuillegauchediv aos-item"
                data-aos="custom"
                data-aos-offset="300"
                data-aos-duration="2000"
                // data-aos-anchor-placement="top-top"
         />
        </div>
      </ContainerRoadmap>

      <ContainerCured id="curedpart">
      <button
          className="info"
          onClick={() => {
            setOpenModalInfo(true);
          }}
        >
          i
        </button>
        {openModalInfo && <ModalInfo closeModal={setOpenModalInfo} />}
        <button className="openModalBtn"></button>
        {openModal && <Modal closeModal={setOpenModal} />}
        <button
          className="openTableauBtn"
          onClick={() => {
            setOpenModalTableau(true);
          }}
        ></button>
        {openModalTableau && <ModalTableau closeModal={setOpenModalTableau} />}
        <button
          className="openVoteBtn"
          onClick={() => {
            setOpenModalVote(true);
          }}
        ></button>
        {openModalVote && <ModalVote closeModal={setOpenModalVote} />}
        <button
          className="openPencilBtn"
          onClick={() => {
            setOpenModalPencil(true);
          }}
        ></button>
        {openModalPencil && <ModalPencil closeModal={setOpenModalPencil} />}
        <button
          className="openMetaverseBtn"
          onClick={() => {
            setOpenModalMetaverse(true);
          }}
        ></button>
        {openModalMetaverse && (
          <ModalMetaverse closeModal={setOpenModalMetaverse} />
        )}
        <button
          className="openNftBtn"
          onClick={() => {
            setOpenModalNft(true);
          }}
        ></button>
        {openModalNft && <ModalNft closeModal={setOpenModalNft} />}
        <button
          className="openFlaconsBtn"
          // onClick={() => {
          //   setOpenModalFlacons(true);
          // }}
        ></button>
        {openModalFlacons && <ModalFlacons closeModal={setOpenModalFlacons} />}
        <button
          className="openBookBtn"
          onClick={() => {
            setOpenModalBook(true);
          }}
        ></button>
        {openModalBook && <ModalBook closeModal={setOpenModalBook} />}
        {/* <Ecureuil5>
          <NavScroll
            to="linkspart"
            smooth={true}
            duration={600}
            spy={true}
            exact="true"
            offset={140}
          >
            <EcureuilImg src={v} alt="v" />
          </NavScroll>
        </Ecureuil5> */}
      </ContainerCured>
      <ContainerLinks id="linkspart">
        <button className="openMarketBtn" />
        <button className="openMailBtn">
          <a
            href="https://linktr.ee/OriginalPainting"
            target="_blank"
            rel="noreferrer"
            
          >Mail</a>
        </button>
        <button className="openMintBtn">
          <a
            href=""
            target="_blank"
            rel="noreferrer"

          >Mint</a>
        </button>
        <button className="openGalleryBtn">
          <a
            href=""
            target="_blank"
            rel="noreferrer"
            
          >Gallery</a>
        </button>

        <button
          className="openVautourBtn"
          onClick={() => {
            setOpenModalEscargotTw(true);
            setHideEscargotTw(false);
            setOpenModalEscargotDc(true);
            setHideEscargotDc(false);
            setOpenModalVautour(true);
            setHideVautour(false);
          }}
        ></button>
        {openModalEscargotTw && (
          <ModalEscargotTw closeModal={setOpenModalEscargotTw}
           />
        )}
        {openModalVautour && (
          <ModalVautour closeModal={setOpenModalVautour} />
        )}
        {openModalEscargotDc && (
          <ModalEscargotDc closeModal={setOpenModalEscargotDc} />
        )}
        {hideEscargotTw ? <div className="escargotFixe"></div> : null}
        {hideEscargotDc ? <div className="escargotDcFixe"></div> : null}
        {hideVautour ? <div className="vautourFixe"></div> : null}
        

        {/* <button
          className="openEscargotTwBtn"
          onClick={() => {
            setOpenModalEscargotTw(true);
            setHideEscargotTw(false);
          }}
        ></button>
        {openModalEscargotTw && (
          <ModalEscargotTw closeModal={setOpenModalEscargotTw} />
        )}
        {hideEscargotTw ? <div className="escargotFixe"></div> : null}

        <button
          className="openEscargotDcBtn"
          onClick={() => {
            setOpenModalEscargotDc(true);
            setHideEscargotDc(false);
          }}
        ></button>
        {openModalEscargotDc && (
          <ModalEscargotDc closeModal={setOpenModalEscargotDc} />
        )}
        {hideEscargotDc ? <div className="escargotDcFixe"></div> : null} */}

      </ContainerLinks>
    </CustomDiv>
  );
};

export default MainPage;

export const CustomDiv = styled.div`
`;

export const AllBackgrounds = styled.div``;

export const ContainerHero = styled.div`
  /* background-color: red; */
  position: relative;
  padding-bottom: 55%;
  width: 100%;
  z-index: 5;
  /* opacity: 0.5; */
  max-height: 200px;
`;
export const ContainerVideo = styled.div`
  /* background-color: blue; */
  position: relative;
  padding-bottom: 65%;
  width: 100%;
  z-index: 5;
  /* opacity: 0.5; */
  display: flex;
  justify-content: center;
  max-height: 200px;
`;
export const ContainerRoadmap = styled.div`
  /* background-color: white; */
  position: relative;
  padding-bottom: 165%;
  width: 100%;
  z-index: 4;
  display: flex;
  align-items: center;
  justify-content: center;
  /* opacity: 0.5; */
  max-height: 200px;
`;
export const ContainerCured = styled.div`
  /* background-color: grey; */
  position: sticky;
  justify-content: center;
  display: flex;
  padding-bottom: 66%;
  width: 100%;
  z-index: 5;
  /* opacity: 0.5; */
  max-height: 200px;
`;

export const ContainerLinks = styled.div`
  /* background-color: pink; */
  position: relative;
  padding-bottom: 60.5%;
  width: 100%;
  z-index: 2;
  /* opacity: 0.5; */
  max-height: 200px;
`;
export const LaTv = styled.div`
  background-color: gold;
  overflow: hidden;
  position: absolute;
  top: 34.2%;
  left: 58.6%;
  width: 7.7%;
  height: 6.8%;
  display: flex;
  /* border: 1rem solid; */
`;

const NavButton = styled(Link)``;

const NavLogo = styled(Link)`
height:100%;
width:100%;
cursor:pointer;
`;

// const NavScroll = styled(LinkS)`
//   justify-self: flex-start;
//   cursor: pointer;
//   width: 100%;
//   height: 100%;
//   vertical-align: bottom;
// `;

const Ecureuil1 = styled.div`
  position: absolute;
  overflow: hidden;
  top: 40%;
  left: 19%;
  z-index: 3;
  /* transform: scale(1.5); */
  /* background-color: pink; */
  @media screen and (max-width: 860px) {
    transform: scale(0.5);
  }
`;


const EcureuilImg = styled.img`
  height: 88px;
  width: 104px;
  vertical-align: bottom;
`;

// ToolBax

// const cursor = document.querySelector(".cursor");

// document.addEventListener("mousemove", (e) => {
//   let x = e.pageX;
//   let y = e.pageY;

//   cursor.style.top = y + "px";
//   cursor.style.left = x + "px";
// });
