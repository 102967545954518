import seaprocess from "../images/ProcessSea.gif";

function SeaProcess() {
  var process = document.getElementById("myImg");
  if (process.src.match(seaprocess)) {
    process.src = seaprocess;
  } else {
    process.src = seaprocess;
  }
}
export default SeaProcess;
