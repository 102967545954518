import { React } from "react";
import "./Modal.css";

function ModalEscargotDc() {
  return (
    <div className="escargotDcMove">
      <a href="https://discord.gg" target="_blank" rel="noreferrer" class="button">
        
      </a>
    </div>
  );
}

export default ModalEscargotDc;
