import landprocess from "../images/ProcessLand.gif";

function LandProcess() {
  var process = document.getElementById("myImg");
  if (process.src.match(landprocess)) {
    process.src = landprocess;
  } else {
    process.src = landprocess;
  }
}
export default LandProcess;
