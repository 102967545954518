import React from "react";
import "./Modal.css";
import introprocess from "../images/Generation_Process_Choice.png";
import LandProcess from "./LandProcess";
import SeaProcess from "./SeaProcess";

function ModalPencil({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Generation Process</h1>
        </div>
        <div className="body">
          <div className="processimages">
            <img src={introprocess} id="myImg" />
          </div>
          <div className="buttons">
            <button
              className="navButton"
              onClick={() => {
                LandProcess();
              }}
            >
              LAND
            </button>

            <button
              className="navButton"
              onClick={() => {
                SeaProcess();
              }}
            >
              SEA
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalPencil;
