import React from "react";
import "./Modal.css";
import nftExample from "../images/nftexample.png";

function ModalNft({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Elements a.k.a Rarity Traits</h1>
        </div>
        <div className="body">
          <p>Up to 14 elements in one NFT</p>
          <div className="nftexample">
            <img src={nftExample} alt="C.U.R.E.D levels" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalNft;
