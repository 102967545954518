import { React } from "react";
import "./Modal.css";
import avatars from "../images/icons/WEBAVATARS_ROLE2.gif"

function ModalLampe({ closeModal }) {
  return (
    <div className="modalBackgroundBase">
      <div className="modalContainerBase">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Creators</h1>
        </div>
        <div className="avatars">
            <img src={avatars} alt= "avatars"/>
        </div>
      </div>
    </div>
  );
}

export default ModalLampe;
