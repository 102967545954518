import React from "react";
import "./Modal.css";
import image1 from "../images/logo512.png";
import image2 from "../images/CollectionSample.png";
import image5 from "../images/Cured_Book.png";
import image7 from "../images/NFT_ID_Book.png";

function ModalBook({ closeModal }) {
  return (
    <div className="modalBackgroundBook">
      <div className="modalContainerBook">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="book">
          <input type="checkbox" id="c1" />
          <input type="checkbox" id="c2" />
          <input type="checkbox" id="c3" />
          <div className="cover">
            <img src={image1} alt='logo512' />
          </div>
          <div className="flip-book">
            <div className="flip" id="p1">
              <div className="back">
                <div className="samples">
                  <img src={image2} className="nftsamples" alt="nft samples" />
                </div>
                <label className="back-btn" htmlFor="c1">
                  Back
                </label>
              </div>
              <div className="front">
                <h1>Original Painting</h1>
                <span className="full-text">
                  <p>
                    Original Painting is an art project that started in
                    September 2021. It aims to democratize digital art and
                    to be a bridge between the real world and the metaverse.
                  </p>
                </span>
                <span className="short-text">
                  <p>
                  Original Painting is an art project that started in
                    September 2021. It aims to democratize digital art and
                    to be a bridge between the real world and the metaverse.
                  </p>
                </span>
                <label className="next-btn" htmlFor="c1">
                  Next
                </label>
              </div>
            </div>

            <div className="flip" id="p2">
              <div className="back">
                <img src={image5} className="curedstats" alt="C.U.R.E.D stats" />
                <label className="back-btn" htmlFor="c2">
                  Back
                </label>
              </div>
              <div className="front">
                <h1>Memphis Collection</h1>
                <span className="full-text">
                  <p>
                    Memphis Collection is the first stone of the Original
                    Painting project. It is a pixel art-based collection of
                    paintings placed on their wood easels. It is also a
                    fantastic adventure inspired by retro gaming and wet-on-wet
                    painting.
                  </p>
                  <p>
                    Each of the 10 000 paintings is a random combination of
                    natural, animal, and special elements, generated among a 200
                    original layers database. 2 sceneries are possible: Land and
                    Sea.
                  </p>
                </span>
                <span className="short-text">
                  <p>
                  Memphis Collection is the first stone of the Original
                    Painting project. It is a pixel art-based collection of
                    paintings placed on their wood easels. It is also a
                    fantastic adventure inspired by retro gaming and wet-on-wet
                    painting.
                    10 000 paintings randomly generated - 200 original layers database - 2 possible sceneries: Land and
                    Sea.
                  </p>
                </span>
                <label className="next-btn" htmlFor="c2">
                  Next
                </label>
              </div>
            </div>

            <div className="flip" id="p3">
              <div className="back">
                <img src={image7} className="nftid" alt="NFT ID" />
                <label className="back-btn" htmlFor="c3">
                  Back
                </label>
              </div>
              <div className="front">
                <h1>C.U.R.E.D Ranking</h1>
                <span className="full-text">
                  <p>
                    C.U.R.E.D is an exclusive and unique ranking system that we
                    developed for the project.
                  </p>
                  <h2>C: Common U: Uncommon R: Rare E: Epic D: Demiurge</h2>
                  <p>
                    The C.U.R.E.D Ranking is stored in the NFT metadata, while
                    the C.U.R.E.D Score will be publicly revealed at the end of
                    the minting phase.
                  </p>
                </span>
                <span className="short-text">
                  <p> C.U.R.E.D is an exclusive and unique ranking system that we
                    developed for the project. </p>
                    <h2>C: Common U: Uncommon R: Rare E: Epic D: Demiurge</h2>
                  <p> (left) total distribution per NFT internal rarity.</p>
                </span>
                <label className="next-btn" htmlFor="c3">
                  Next
                </label>
              </div>
            </div>

            <div className="flip" id="p4">
              <div className="back">
                <label className="back-btn" htmlFor="c4">
                  Back
                </label>
              </div>
              <div className="front">
                <h1>NFT Identity Card</h1>
                <span className="full-text">
                  <p>
                    The 452x452px NFT consists in a painting sitting on its
                    easel, without background. You can get to grips with it and
                    place it in your favorite digital environment!
                  </p>

                  <button className="bookButton">
                    <a
                      href="https://original-painting.gitbook.io/original-painting/"
                      target="_blank"
                      rel="noreferrer"
                      className="button"
                    >
                      Discover more
                    </a>
                  </button>
                </span>

                <span className="short-text">
                  <p>
                  The 452x452px NFT consists in a painting sitting on its
                    easel, without background. You can get to grips with it and
                    place it in your favorite digital environment!
                  </p>
                  <button className="bookButton">
                    <a
                      href="https://original-painting.gitbook.io/original-painting/"
                      target="_blank"
                      rel="noreferrer"
                      className="button"
                    >
                      Discover more
                    </a>
                  </button>
                </span>
                <label className="next-btn" htmlFor="c4">
                  Next
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalBook;
