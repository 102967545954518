import React from 'react';
import MainPage from '../elements/Main';

function Home() {

    return (
<>
<MainPage/>
</>
    );
};

export default Home;