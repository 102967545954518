import React from "react";
import "./Modal.css";

function ModalEscargotTw() {
  return (
    <div className="escargotMove">
      <a href="https://twitter.com/OPainting_" target="_blank" rel="noreferrer" class="button">
        
      </a>
    </div>
  );
}

export default ModalEscargotTw;
